import FsLightbox from 'fslightbox-react';
import { useContext, useEffect, useState } from 'react'
import { PageSelector } from '../components/PageSelector';
import GlobalContext from '../context/GlobalContext';
import '../styles/Gallery.css'

export const Gallery = () => {
  const { fetchGalleryPhotos } = useContext(GlobalContext);
  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [filenames, setFilenames] = useState([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalItems: 0
  });
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });
  useEffect(() => {
    const fetchData = async () => {
      const { photos: files, ...rest } = await fetchGalleryPhotos(page);
      if (files) {
        filenames[page] = files.sort((a, b) => a.placement - b.placement).map(file => file.filename);
        photos[page] = files.sort((a, b) => a.placement - b.placement);
        setFilenames(rest => [...rest]);
        setPhotos(rest => [...rest]);
        setPageData(rest);
      }
    }
    if(lastPage !== page && (!filenames[page] || !photos[page])) {
      fetchData();
      setLastPage(page);
    }
  }, [fetchGalleryPhotos, page, lastPage, filenames, photos])

  const openLightboxOnSlide = (filename) => {
    let number = filenames[page].findIndex(file => file === filename);
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number === -1 ? 0 : number + 1
    });
  }
  return (
    <div className='gallery-container container'>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={filenames[page]} type="image"
        key={page}
      />
      <PageSelector pages={pageData.totalPages} items={pageData.totalItems} currentPage={page} setPage={setPage} key='gallery' />
      <div className='gallery-photos'>
        {photos[page] ? photos[page].map(pic =>
          <img className='gallery-photo'
            src={pic.thumb ? pic.thumb : pic.filename} key={pic.filename}
            onClick={() => openLightboxOnSlide(pic.filename)} alt='gallery' />
        ) : <></>}
      </div>
    </div>
  )
}
