import { UploadingFile } from "./UploadingFile"
import '../../styles/admin/UploadingFiles.css'

export const UploadingFiles = ({ files, retryUpload, cancel }) => {
    return (
        <div className='uploading-files-container'>
            {files.map(file => {
                return <UploadingFile file={file} retryUpload={retryUpload} cancel={cancel} />
            })}
        </div>
    )
}
