import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext"

export const Login = () => {
    const { auth } = useContext(GlobalContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        if(await auth(username, password)) {
            setError(null);
            navigate('/admin');
        } else {
            setError("Złe dane");
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                    {error ? <p>{error}</p> : <></>}
                <label>
                    <p>Username</p>
                    <input type="text" onChange={e => setUsername(e.target.value)}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}
