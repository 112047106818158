import '../styles/Footer.css'

export const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='copy'>
               <h5>Realizacja <a target="_blank" rel='noopener noreferrer' href="https://cloverit.pl">CloverIT</a>&copy; 2023 </h5>
        </div>
    </div>
  )
}
