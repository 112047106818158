import React from 'react'

export const Timetable = (props) => {
const { timetable } = props;

  return (
    <div className='plan'>
          <h2>{timetable.title}</h2>
          <div className='dots'>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <div className='lines'>
            {timetable.classes.map(c => {
              return <>
                <h3>{c.hour} {c.paragraph}</h3>
                <hr />
              </>
            })}
          </div>      
        </div>
  )
}
