import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadingFiles } from "../../../components/admin/UploadingFiles";
import GlobalContext from "../../../context/GlobalContext";
import '../../../styles/admin/AddGalleryFiles.css'

export const AddGalleryFiles = () => {
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const { url, addGalleryFiles } = useContext(GlobalContext);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const [queue, setQueue] = useState([]);
  const [queueNotInitialized, setQueueNotInitialized] = useState(true);
  let numOfWorkers = 0;
  let taskIndex = 0;


  const onFilesChange = e => {
    let tempFilesList = [];
    Array.from(e.target.files).forEach(file => {
      if (!uploadingFiles.map(f => f.file.name).includes(file.name)) {
        tempFilesList.push({
          file: file,
          started: false,
          uploaded: false,
          hasErrored: false,
          id: ''
        });
      }
    });
    setUploadingFiles(files => [...files, ...tempFilesList])
  }

  useEffect(() => {
    function runQueue() {
      const handleResult = async (index, response) => {
        let indexF = uploadingFiles.findIndex(f => f === queue[index]);
        if (response.status === 200) {
          let responseData = await response.text();
          uploadingFiles[indexF].id = responseData;
          uploadingFiles[indexF].uploaded = true;
        } else {
          uploadingFiles[indexF].hasErrored = true;
        }
        uploadingFiles[indexF].done = true;
        setUploadingFiles(files => [...files]);
        queue[index].uploaded = uploadingFiles[indexF].uploaded;
        queue[index].hasErrored = uploadingFiles[indexF].hasErrored;
        queue[indexF].done = uploadingFiles[indexF].done;
        setQueue(q => [...q])
        numOfWorkers--;
        getNextTask();
      };
      const getNextTask = () => {
        if (numOfWorkers < 4 && taskIndex < queue.length) {
          const constIndex = taskIndex;
          if (!queue[constIndex].started) {
            uploadingFiles[constIndex].started = true;
            let formData = new FormData();
            formData.append('photo', queue[constIndex].file);
            fetch(url + 'admin/photo/public', {
              method: 'POST',
              body: formData
            })
              .then(response => handleResult(constIndex, response))
              .catch(response => handleResult(constIndex, response));
            numOfWorkers++;
          }
          taskIndex++;
          getNextTask();
        } else if (numOfWorkers === 0 && taskIndex === queue.length) {
          setQueueNotInitialized(true);
        }
      };
      getNextTask();
    }
    if (queue && queue.length > 0 && (queue.every(file => file.done) || queueNotInitialized)) {
      setQueueNotInitialized(false);
      runQueue();
    }
  }, [queue, url, uploadingFiles, numOfWorkers, taskIndex, queueNotInitialized])

  useEffect(() => {
    const uploadFile = (fileToUpload) => {
      let index = uploadingFiles.findIndex(f => f === fileToUpload);
      uploadingFiles[index].queued = true;
      setQueue(queue => [...queue, fileToUpload]);
    }

    uploadingFiles.forEach(f => {
      if (!f.queued) {
        uploadFile(f);
      }
    })

    if (uploadingFiles.length > 0) {
      if (uploadingFiles.every(f => f.done)) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      setButtonDisabled(true);
    }
  }, [uploadingFiles])

  const onSubmitHandler = () => {
    setButtonDisabled(true);
    const response = addGalleryFiles(uploadingFiles.filter(f => !f.hasErroed)
      .map(f => {
        return {
          id: f.id,
          placement: uploadingFiles.findIndex(file => file === f)
        }
      }));
    if (response) {
      navigate('/admin')
    } else {
      setButtonDisabled(false);
    }
  }

  const onSelectHandler = () => {
    inputFileRef.current.click();
  }

  const retryFileUpload = (file) => {
    const index = uploadingFiles.findIndex(f => f === file);
    uploadingFiles[index].queued = false;
    uploadingFiles[index].started = false;
    uploadingFiles[index].uploaded = false;
    uploadingFiles[index].hasErrored = false;
    uploadingFiles[index].done = false;
    setUploadingFiles(f => [...f]);
  }

  const cancelFile = (file) => {
    setUploadingFiles(uploadingFiles.filter(f => f !== file))
  }

  return (
    <div>
      <UploadingFiles files={uploadingFiles} retryUpload={retryFileUpload} cancel={cancelFile} />
      <input type='file' multiple
        className='custom-file-input'
        ref={inputFileRef}
        onChange={onFilesChange} accept="image/*, video/*" />
      <button onClick={onSelectHandler}>Wybierz pliki</button>
      <button disabled={buttonDisabled}
        className={buttonDisabled ? 'button-disabled' : ''}
        onClick={onSubmitHandler} >Dodaj zdjęcia do galerii</button>
    </div>
  )
}
