import { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { News } from './pages/News';
import { Parent } from './pages/Parent';
import { Gallery } from './pages/Gallery';
import GlobalContext from './context/GlobalContext';
import { ProtectedRoute } from './util/ProtectedRoute'
import { ParentRoute } from './util/ParentRoute'
import { Dashboard } from './pages/admin/Dashboard';
import { ParentLogin } from './pages/auth/ParentLogin';
import { Login } from './pages/auth/Login';
import { AddGalleryFiles } from './pages/admin/gallery/AddGalleryFiles';
import { DeleteGalleryFiles } from './pages/admin/gallery/DeleteGalleryFiles';
import { UpdateGalleryFiles } from './pages/admin/gallery/UpdateGalleryFiles';
import './App.css';
import { UpdateParentTitle } from './pages/admin/parent/UpdateParentTitle';
import { AddParentMonth } from './pages/admin/parent/AddParentMonth';
import { AddParentFolder } from './pages/admin/parent/AddParentFolder';
import { EditParent } from './pages/admin/parent/EditParent';

function App() {
  const { loading } = useContext(GlobalContext);
  if (loading) {
    return (
      <>
        loading
      </>
    )
  } else {
    return (
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/onas' element={<About />} />
          <Route path='/aktualnosci' element={<News />} />
          <Route element={<ParentRoute />} path='/strefa' >
            <Route element={<Parent />} path='/strefa' />
          </Route>
          <Route path='/galeria' element={<Gallery />} />
          <Route element={<ProtectedRoute />} path='/admin' >
            <Route element={<Dashboard />} path='/admin' />
            <Route element={<AddGalleryFiles />} path='/admin/gallery' />
            <Route element={<DeleteGalleryFiles />} path='/admin/gallery/delete' />
            <Route element={<UpdateGalleryFiles />} path='/admin/gallery/update' />
            <Route element={<EditParent />} path='/admin/parent' />
            <Route element={<UpdateParentTitle />} path='/admin/parent/title' />
            <Route element={<AddParentMonth />} path='/admin/parent/month' />
            <Route element={<AddParentFolder />} path='/admin/parent/month/:id' />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/parent-login' element={<ParentLogin />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
