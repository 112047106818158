import '../../styles/admin/UploadingFile.css'

export const UploadingFile = ({ file, retryUpload, cancel }) => {
    return (
        <div className='uploading-file'>
            <h5>{file.file.name}</h5>
            <img src={URL.createObjectURL(file.file)} alt='uploading-file' />
            {file.uploaded ?
                <div>
                    <img src='/images/done.png' alt='done' key={file.file.name} />
                    <span>Wysłane</span>
                </div>
                : file.hasErrored ?
                    <div>
                        <span>Wystąpił błąd</span>
                        <img src='/images/restart.png' alt='retry' onClick={() => retryUpload(file)} />
                        <img src='/images/navbar_close.png' alt='remove' onClick={() => cancel(file)} />
                    </div>
                    : <div>
                        <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <span>Wysyłanie</span>
                    </div>}
        </div>
    )
}