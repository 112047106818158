import React from 'react'
import { Link } from 'react-router-dom'

export const AdminSidebar = () => {
  return (
    <div>
      <ul className='admin-sidebar' >
        <li>Aktualności
          <ul>
            <li className='sidebar-item'>
              <Link to='/admin/news' className='sidebar-links'>
                Dodaj aktualności
              </Link>
            </li>
          </ul>
        </li>
        <li>Strefa Rodzica
          <ul>
            <li className='sidebar-item'>
              <Link to='/admin/parent' className='sidebar-links'>
                Edytuj strefę rodzica
              </Link>
            </li>
          </ul>
        </li>
        <li>Galeria
          <ul>
            <li className='sidebar-item'>
              <Link to='/admin/gallery' className='sidebar-links'>
                Dodaj zdjęcia
              </Link>
            </li>
            <li className='sidebar-item'>
              <Link to='/admin/gallery/delete' className='sidebar-links'>
                Usuń zdjęcia
              </Link>
            </li>
            <li className='sidebar-item'>
              <Link to='/admin/gallery/update' className='sidebar-links'>
                Ustaw zdjęcia
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
