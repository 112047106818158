import { useContext, useEffect, useState } from "react"
import { PageSelector } from "../components/PageSelector";
import { ParentMonth } from "../components/ParentMonth";
import GlobalContext from "../context/GlobalContext"

export const Parent = () => {
  const { fetchParentData } = useContext(GlobalContext);
  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [months, setMonths] = useState([]);
  const [title, setTitle] = useState('');
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalItems: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const { months: listMonths, title: titleResponse, ...rest } = await fetchParentData(page);
      if (listMonths) {
        months[page] = listMonths.sort((a, b) => a.placement - b.placement);
        setTitle(titleResponse);
        setMonths(rest => [...rest]);
        setPageData(rest);
      }
    }
    if(lastPage !== page && !months[page]) {
      fetchData();
      setLastPage(page);
    }
  }, [fetchParentData, page, lastPage, months])

  return (
    <div className='parent-container container'>
      <PageSelector pages={pageData.totalPages} items={pageData.totalItems} currentPage={page} setPage={setPage} key='parent' />
      {title}
      {months[page] ? months[page].map(month => <ParentMonth month={month} key={month.title + month.placement} />) : <></>}
    </div>
  )
}
