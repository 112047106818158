import { useContext, useState, useEffect } from 'react'
import GlobalContext from '../context/GlobalContext'
import { Downloads } from '../components/Downloads'
import { NewsModule } from '../components/NewsModule'
import { ExtraClasses } from '../components/ExtraClasses'
import { Timetable } from '../components/Timetable'

export const News = () => {
  const { url } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let headers = {
        "Accept": "application/json"
      }
      let response = await fetch(url + 'data/news', {
        method: 'GET',
        headers: headers
      })
      let responseData = await response.json();

      if (response.status === 200) {
        setNewsData(responseData);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    }
    if(loading) {
      getData();
    }
  }, [loading, url])


  return (
    <div className='news-container container'>
      {loading ? <>LOADING...</>
        : error ? <>ERROR</>
        : <>
          {newsData.files && newsData.files.length > 0 ? <Downloads files={ newsData.files } /> : <></>}
          <NewsModule />
          <ExtraClasses extra={ newsData.classes } />
          <Timetable timetable={ newsData.timeTable } />
        </>}
    </div>
  )
}