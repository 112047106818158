import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";

export const ParentFolder = (props) => {
  const { folder } = props;
  const [filenames, setFilenames] = useState([]);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  useEffect(() => {
    if(folder && folder.photos) {
      const sortedPhotos = [...folder.photos].sort((a, b) => b.placement - a.placement)
      .map(photo => photo.filename);
      setFilenames(sortedPhotos);
    }
  }, [folder, folder.photos])

  const openLightbox = () => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: 1
    });
  }

  return (
    <div>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={filenames} type="image"
        key={folder.title + folder.placement}
      />
      <img src='/images/folders.png' alt='folder' onClick={openLightbox} />
      {folder.title}
    </div>
  )
}
