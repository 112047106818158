import { Link } from 'react-router-dom';
import '../styles/Home.css'

export const Home = () => {
    return (
        <div className='hero-container container' id="overlay">
            <div className='text'>
                <h1>Przedszkole XYZ</h1>
                <p>Miejsce dla twojej pociechy</p>
                <p className='zapisy-text'>Zapisy cały rok!</p>
            </div>
            <div className='items-container'>
                <div className='item news'>
                    <img src="/images/aktualnosci.png" alt='news' />
                    <h2>Aktualności</h2>
                    <br />
                    <Link to="/aktualnosci">
                        <button>
                            <h3>Więcej</h3>
                        </button>
                    </Link>
                </div>
                <div className='item contact'>
                    <img src="/images/kontakt.png" alt='contact' />
                    <h2>Kontakt</h2>
                    <br />
                    <h4>7:00-16:00</h4>
                    <h4>ul. Przykładowa 1</h4>
                    <h4>
                        <a href="mailto: kontakt@example.pl">kontakt@example.pl</a>
                    </h4>
                    <br />
                    <h3>tel: <a href="tel:XXXXXXXXX">XX XXX XX XX</a></h3>
                    <h3>kom: <a href="tel:XXXXXXXXX">XXX XXX XXX</a></h3>
                </div>
                <div className='item sun'>
                    <img src="/images/sun.png" alt='sun' />
                    <h2>Dyrektor</h2>
                    <br />
                    <h4>Imię Nazwisko</h4>
                </div>
            </div>
        </div>
    )
}
