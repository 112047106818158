import { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import GlobalContext from '../context/GlobalContext';
import '../styles/Navbar.css';

export const Navbar = () => {
    const { role } = useContext(GlobalContext);
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/" className="navbar-logo">
                    <img id='navbar-logo' src={'/images/sun.png'} alt='logo' />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <img src={click ? './images/navbar_close.png' : './images/navbar_lines.png'} alt='menu' />
                </div>
                <ul className={click && role ? 'nav-menu authenticated active' :
                click ? 'nav-menu active' :
                role ? 'nav-menu authenticated' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Strona główna
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/onas' className='nav-links' onClick={closeMobileMenu}>
                            O nas
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/aktualnosci' className='nav-links' onClick={closeMobileMenu}>
                            Aktualności
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/strefa' className='nav-links' onClick={closeMobileMenu}>
                            Strefa rodzica
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/galeria' className='nav-links' onClick={closeMobileMenu}>
                            Galeria
                        </Link>
                    </li>
                    {['ADMIN', 'SUPERUSER'].includes(role) ? 
                    <li className='nav-item'>
                        <Link to='/admin' className='nav-links' onClick={closeMobileMenu}>
                            Panel Admina
                        </Link>
                    </li> : <></>}
                </ul>
            </div>
        </nav>
    )
}