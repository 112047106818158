import { createContext, useEffect, useState } from 'react'

const GlobalContext = createContext();

export default GlobalContext;

export const GlobalProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const url = 'https://test.cloverit.pl/api/v1/';
    const [role, setRole] = useState(null);
    const [time, setTime] = useState(0);

    const auth = async (username, password) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "username": username,
            "password": password
        }
        let response = await fetch(url + 'auth', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            let responseData = await response.json();
            setRole(responseData.role);
            setTime(responseData.time);
            return true;
        } else {
            return false;
        }
    }

    const authParent = async (password) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "password": password
        }
        let response = await fetch(url + 'auth/parent', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            let responseData = await response.json();
            setRole(responseData.role);
            setTime(responseData.time);
            return true;
        } else {
            return false;
        }
    }

    const addGalleryFiles = async (files) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "fileIds": files
        }
        let response = await fetch(url + 'admin/gallery', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    const fetchGalleryPhotos = async (page) => {
        let response = await fetch(url + 'data/gallery?page=' + page, {
            method: 'GET'
        })
        const responseData = await response.json();
        if (response.status === 200) {
            const { photos, ...data } = responseData;
            let mappedData = {
                ...data,
                photos: photos.map(({ filename, thumb, ...rest }) => {
                    return {
                        ...rest,
                        filename: url + 'data/public/' + filename,
                        thumb: thumb ? url + 'data/public/' + thumb : null
                    }
                })
            }
            return mappedData;
        }
        return {
            totalPages: 0,
            totalItems: 0,
            photos: []
        }
    }

    const deleteGalleryFiles = async (filenames) => {
        let headers = {
            "Content-Type": "application/json"
        }
        let body = {
            "filenames": filenames
        }
        let response = await fetch(url + 'admin/gallery', {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
        return response.status === 200;
    }

    const updateGalleryFiles = async (files) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "files": files
        }
        let response = await fetch(url + 'admin/gallery', {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        })
        return response.status === 200;
    }

    const fetchParentData = async (page) => {
        let response = await fetch(url + 'parent?page=' + page, {
            method: 'GET'
        })
        const responseData = await response.json();
        if (response.status === 200) {
            const { months, ...data } = responseData;
            let mappedData = {
                ...data,
                months: months.map(({ folders, ...rest }) => {
                    return {
                        ...rest,
                        folders: folders.map(({ photos, ...rest }) => {
                           return {
                                ...rest,
                                photos: photos.map(({ filename, thumb, ...rest }) => {
                                    return {
                                        ...rest,
                                        filename: url + 'parent/' + filename,
                                        thumb: thumb ? url + 'parent/' + thumb : null
                                    }
                                })
                           }
                        })
                    }
                })
            }
            return mappedData;
        }
        return {
            totalPages: 0,
            totalItems: 0,
            photos: []
        }
    }
    
    const updateParentTitle = async (title) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "title": title,
            "placement": 0
        }
        let response = await fetch(url + 'admin/parent/title', {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            let responseData = await response.text();
            return responseData;
        } else {
            return null;
        }
    }
    
    const addParentMonth = async (title) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "title": title,
            "placement": 0
        }
        let response = await fetch(url + 'admin/parent/month', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            let responseData = await response.text();
            return responseData;
        } else {
            return null;
        }
    }
    
    const addParentFolder = async (title, id) => {
        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        let body = {
            "title": title,
            "placement": 0
        }
        let response = await fetch(url + 'admin/parent/' + id + '/folder', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if (response.status === 200) {
            let responseData = await response.text();
            return responseData;
        } else {
            return null;
        }
    }

    useEffect(() => {
        const refreshAuth = async () => {
            let headers = {
                "Accept": "application/json"
            }
            let response = await fetch(url + 'auth/refresh', {
                method: 'POST',
                headers: headers
            })
            if (response.status === 200) {
                let responseData = await response.json();
                setRole(responseData.role);
                setTime(responseData.time);
                setLoading(false);
            } else {
                setRole(null);
                setLoading(false);
            }
        }
        if (loading) {
            refreshAuth()
        }
        let interval = setInterval(() => {
            if (role) {
                refreshAuth();
            }
        }, Number.parseInt(time) * 60 * 1000 - 1000);
        return () => clearInterval(interval);
    }, [loading, role, time])

    let contextData = {
        loading: loading,
        url: url,
        role: role,
        auth: auth,
        authParent: authParent,
        addGalleryFiles: addGalleryFiles,
        fetchGalleryPhotos: fetchGalleryPhotos,
        deleteGalleryFiles: deleteGalleryFiles,
        updateGalleryFiles: updateGalleryFiles,
        fetchParentData: fetchParentData,
        updateParentTitle: updateParentTitle,
        addParentMonth: addParentMonth,
        addParentFolder: addParentFolder
    }

    return (
        <GlobalContext.Provider value={contextData}>
            {loading ? null : children}
        </GlobalContext.Provider>
    )
}