import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext"

export const UpdateParentTitle = () => {
    const { updateParentTitle } = useContext(GlobalContext);
    const [isSubmitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const onFormSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        let response = updateParentTitle(e.target.title.value.trim())
        if(response) {
            navigate('/admin/parent');
        }
        setSubmitting(false);
    }

    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <label>
                    <span>Tytuł</span>
                    <input type='text' id='title' />
                </label>
                <button disabled={isSubmitting} >Dalej</button>
            </form>
        </div>
    )
}