import React, { useContext } from 'react'
import GlobalContext from '../context/GlobalContext';

export const Downloads = (props) => {
  const { url } = useContext(GlobalContext);
  const { files } = props;

  return (
    <div>
      <h3>Pliki do pobrania</h3>
      {files.map(file => {
        return <h4>
          <a href={url + 'data/public/' + file.filename} download={file.downloadName}>
            <img src='/icons/file.svg' alt='file' /> {file.downloadName}
          </a>
        </h4>
      })}
    </div>
  )
}
