export const ExtraClasses = (props) => {
    const { classes } = props;

    return (
        <div className='extra'>
            {!classes ? <></>
            : <>
            <table cellSpacing="10">
                <tr className='header-table-row'>
                    <th colSpan="6" className='header-table'>
                        <h2>{classes.title}</h2>
                        <h3>{classes.subtitle}</h3>
                    </th>
                </tr>
                <div className='table-row'>
                    {classes.map(c => {
                        return <tr>
                            <td align="center" key={c.dayNum}>
                                <h3>c.dayName</h3>
                                <hr />
                                <h4>c.paragraph</h4>
                            </td>
                        </tr>
                    })}
                </div>
            </table>
            <h4 style={{ margin: "1%" }}>{classes.legend}</h4>
            </>}
        </div>
    )
}