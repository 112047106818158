import '../styles/About.css'

export const About = () => {
  return (
    <div className='about-container'>
      <div className='main'>
        <h1>Co daje dziecku przebywanie w grupie zróżnicowanej wiekowo?</h1>
        <br />
        <br />
        <h3>Jest naturalnym środowiskiem rozwoju dziecka. Przedszkolak nie uczy się tylko od równolatków, ale także od młodszych i starszych dzieci, tak jak dzieje się to w życiu.</h3>
        <br />
        <br />
        <h3>Przebywanie w grupie mieszanej ma duży wpływ na rozwój dziecka. Dziecko szybciej dostrzega i rozumie zróżnicowane potrzeby i zasady współżyciaspołecznego. Dzieci zamiast rywalizować, współpracują. Starszaki stają się bardziej odpowiedzialne, kompetente i czują się bardziej potrzebne, przez co wzrasta ich poczucie wartości i odpowiedzialności. Młodsze dzieci inspirują się dokonaniami starszych dzieci, nie czują się osamotnione, mogą liczyć na starszych kolegów.</h3>
        <br />
        <br />
        <h3>Przyjazna, naturalna adaptacja nowych dzieci przebiega szybciej i sprawniej. Nowym dzieciom łatwiej jest przyjąć grupowe zasady. Dzieci naturalnie ucza się zaangażowania i opiekuńczości wobec młodszych, ale również słabszych. Starsi mają możliwość prezentowania swojej wiedzy.</h3>
        <br />
        <br />
        <h3>Grupa zróżnicowana wiekowo ułatwia indywizualizację oddziaływań wychowawczych, nie tylko ze względu na wiek, ale również możliwości rozwojowe poszczególnych dzieci. W takiej grupie można uwzględnić wszelkie niedostatki bez wytykania ich i zwracania na nie uwagi.</h3>
        <br />
        <br />
        <h3>Następuje naturalna i samoistna nauka postaw tolerancji, partnerstwa i współżycia w środowiskach nie nieograniczonych jedynie do rówieśników.</h3>
        <br />
        <br />
        <h3>Tworzymy na codzień miejsce, gdzie troskliwa opieka, wysoka jakość uczenia, życzliwa atmosfera i pozytywna dyscyplina wspierają rozwój dzieci zgodnie z Podstawą Programową Wychowania Przedszkolnego.</h3>
        <br />
        <br />
      </div>
      <div className='main-img'>
        <br />
        <div className='main-mini' style={{ backgroundColor: 'rgb(255, 99, 226)' }}>
          <img src='/images/about1.png' alt='about1' />
          <br />
          <h3>Spełniamy wszystkie wymagania przepisów techniczno-budowlanych, przeciwpożarowych oraz sanitarno-higienicznych.</h3>
        </div>
        <div className='main-mini' style={{ backgroundColor: ' rgb(0, 250, 180)' }}>
          <img src='/images/about2.png' alt='about2' />
          <br />
          <h3>Jesteśmy Przedszkolem Niepublicznym wpisanym do ewidencji prowadzonej przez Departament Edukacji Urzędu Miasta w Białymstoku.</h3>
        </div>
        <div className='main-mini' style={{ backgroundColor: 'rgb(230, 247, 164)' }}>
          <img src='/images/about3.png' alt='about3' />
          <br />
          <h3>Nasze działania opiekuńcze i edukacyjne są objęte stałą opieką i kontrolą Podlaskiego Kuratora Oświaty.</h3>
        </div>
      </div>
      <div className='main'>
        <h2>Pare słów o naszej placówce</h2>
        <br />
        <br />
        <h3>Nasza placówka przedszkolna pracuje od 2014 roku. Zaproponowaliśmy dzieciom naukę i zabawę w kameralnej grupie przedszkolnej o strukturze rodzinkowej.</h3>
        <img src="/images/kids.png" alt='kids' />
        <img src="/images/toys.png" alt='toys' />
        <br />
        <br />
      </div>
      <div className='main-podzial'>
        <div className='kafelek tlo'>
          <h2>kameralna grupa przedszkolna</h2>
        </div>
        <div className='kafelek tlo stack'>
          <h2>smaczny catering (stosujemy wszystkie wymagania diety)</h2>
        </div>
        <div className='kafelek tlo stack'>
          <h2>profesjonalna, troskliwa opieka</h2>
        </div>
        <div className='kafelek tlo stack'>
          <h2>honorujemy Kartę Dużej Rodziny</h2>
        </div>
        <div className='kafelek tlo stack'>
          <h2>zniżki dla rodzeństwa</h2>
        </div>
      </div>
    </div>
  )
}
