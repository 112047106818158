import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext"
import '../../styles/auth/ParentLogin.css'

export const ParentLogin = () => {
    const { authParent } = useContext(GlobalContext);
    const [password, setPassword] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        if (await authParent(password)) {
            setError(null);
            navigate('/strefa');
        } else {
            setPassword("");
            setError("Złe hasło");
        }
    }

    return (
        <div className='strefa-container password-page'>
            <div className='strefa-rodzic'>
                <div className='top-text'>
                    <div className='strefa-text'>
                        <h1>Strefa Rodzica</h1>
                        <h3>Wpisy zabezpieczone hasłem</h3>
                    </div>
                    <div className='strefa-logo'>
                        <img className='navbar-logo' id='navbar-logo' src={'/images/sun.png'} alt='logo' />
                    </div>
                </div>
                <form onSubmit={handleSubmit} className='parent-form' >
                    {error ? <p className='error-message'>{error}</p> : <></>}
                    <div className='password-input'>
                        <div className='input-eye'>
                            <img src={isRevealPwd ? "/images/visibility_off.png" : "/images/visibility_on.png"}
                                className='visibility-button'
                                alt='reveal password'
                                onClick={() => setIsRevealPwd(prevState => !prevState)} />
                            <input
                                name="pwd"
                                className="input-field"
                                placeholder="Wpisz hasło"
                                type={isRevealPwd ? "text" : "password"}
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                        </div>
                        <button className="button-strefa" >Wejdź</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
