import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ParentFolder } from './ParentFolder'

export const ParentMonth = (props) => {
  const { month, isEdit } = props;
  const [folders, setFolders] = useState([]);
  
  useEffect(() => {
    if(month && month.folders) {
      const sortedFolders = [...month.folders].sort((a, b) => b.placement - a.placement);
      setFolders(sortedFolders);
    }
  }, [month, month.folders])

  return (
    <div>
      {month.title}
      {isEdit ? <Link to={'/admin/parent/month/' + month.id} >
        <img src='/images/plus.png' alt='add' />
      </Link> : <></>}
      {folders ? folders.map(folder => <ParentFolder folder={folder} isEdit={isEdit} key={folder.title + folder.placement} />) : <></>}
    </div>
  )
}
