import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { PageSelector } from "../../../components/PageSelector";
import GlobalContext from "../../../context/GlobalContext"
import '../../../styles/admin/DeleteGalleryFiles.css'

export const DeleteGalleryFiles = () => {
  const { fetchGalleryPhotos, deleteGalleryFiles } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalItems: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const { photos: files, ...rest } = await fetchGalleryPhotos(page);
      if (files) {
        photos[page] = files.sort((a, b) => a.placement - b.placement);
        setPhotos(photos => [...photos]);
        setPageData(rest);
      }
    }
    if (lastPage !== page && !photos[page]) {
      fetchData();
      setLastPage(page);
    }
  }, [fetchGalleryPhotos, page, lastPage, photos])

  const picToDelete = (pic) => {
    let index = photos[page].findIndex(photo => photo === pic);
    photos[page][index].toDelete = !photos[page][index].toDelete;
    setPhotos(photos => [...photos]);
  }

  const onSubmit = async () => {
    let photosToDelete = [];
    for (let i = 0; i < photos.length; i++) {
      photosToDelete = photosToDelete.concat(photos[i].filter(photo => photo.toDelete)
        .map(photo => {
          let index = photo.filename.lastIndexOf('/') + 1;
          return photo.filename.substring(index);
        }))
    }
    let response = await deleteGalleryFiles(photosToDelete)
    if (response) {
      navigate('/admin');
    }
  }

  return (
    <div>
      <PageSelector pages={pageData.totalPages} items={pageData.totalItems} currentPage={page} setPage={setPage} key='delete-gallery' />
      <div className='gallery-photos-delete'>
        {photos[page] ? photos[page].map(pic =>
          <div key={pic.filename} className='delete-gallery-photo-container'>
            <img className="gallery-delete-button" src="/images/navbar_close.png" alt="delete" onClick={() => picToDelete(pic)} />
            <img className={pic.toDelete ? "gallery-photo-delete to-delete" : "gallery-photo-delete"}
              src={pic.thumb ? pic.thumb : pic.filename}
              alt='gallery' key={pic.filename} />
          </div>
        ) : <></>}
      </div>
      <button onClick={onSubmit} >Zatwierdź</button>
    </div>
  )
}
