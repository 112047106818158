export const PageSelector = (props) => {
    const { pages, items, currentPage, setPage } = props;
    
    const onPrevClick = () => {
        if(currentPage > 0) {
            setPage(currentPage - 1);
        }
    }
    const onNextClick = () => {
        if(currentPage + 1 < pages) {
            setPage(currentPage + 1);
        }
    }

  return (
    <div>
        <img
            alt='left-arrow'
            onClick={onPrevClick} />
        <span>
            {currentPage + 1}/{pages > 0 ? pages : 1}
        </span>
        <img
            alt='right-arrow'
            onClick={onNextClick} />
        <span>
            {items} total items
        </span>
    </div>
  )
}
