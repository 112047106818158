import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { PageSelector } from "../../../components/PageSelector";
import GlobalContext from "../../../context/GlobalContext"
import '../../../styles/admin/UpdateGalleryFiles.css'

export const UpdateGalleryFiles = () => {
  const { fetchGalleryPhotos, updateGalleryFiles } = useContext(GlobalContext);
  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalItems: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const { photos: files, ...rest } = await fetchGalleryPhotos(page);
      if (files) {
        photos[page] = files.sort((a, b) => a.placement - b.placement);
        setPhotos(photos => [...photos]);
        setPageData(rest);
      }
    }
    if (lastPage !== page && !photos[page]) {
      fetchData();
      setLastPage(page);
    }
  }, [fetchGalleryPhotos, page, lastPage, photos])

  const onSubmitHandler = () => {
    let photosToUpdate = [];
    for (let i = 0; i < photos.length; i++) {
      photosToUpdate = photosToUpdate.concat(photos[i].filter(photo => photo.updated)
        .map(photo => {
          let index = photo.filename.lastIndexOf('/') + 1;
          return {
            filename: photo.filename.substring(index),
            placement: photo.placement
          }
        }))
    }
    const response = updateGalleryFiles(photosToUpdate);
    if (response) {
      navigate('/admin')
    }
  }

  const findPageAndIndexOf = (file) => {
    for (let i = 0; i < photos.length; i++) {
      let indexIn = photos[i].findIndex(photo => photo === file);
      if (indexIn > -1) {
        return {
          pageOn: i,
          index: indexIn
        }
      }
    }
    return {
      pageOn: -1,
      index: -1
    }
  }

  const photoUp = async (file) => {
    const { pageOn, index } = findPageAndIndexOf(file);
    if (pageOn < 0 || index < 0) return;
    if (pageOn === 0 && index === 0) return;
    if (index > 0) {
      let tempPrev = photos[pageOn][index - 1];
      let tempCurrent = photos[pageOn][index];
      let tempPrevPlacement = tempPrev.placement;
      tempPrev.placement = file.placement;
      tempCurrent.placement = tempPrevPlacement;
      tempPrev.updated = true;
      tempCurrent.updated = true;
      photos[pageOn][index - 1] = tempCurrent;
      photos[pageOn][index] = tempPrev;
      setPhotos(photos => [...photos]);
      return;
    }
    if(!photos[pageOn - 1]) {
      const { photos: files } = await fetchGalleryPhotos(pageOn - 1);
      photos[pageOn - 1] = files.sort((a, b) => a.placement - b.placement);
      setPhotos(photos => [...photos]);
    }
    let tempPrev = photos[pageOn - 1][49];
    let tempCurrent = photos[pageOn][index];
    let tempPrevPlacement = tempPrev.placement;
    tempPrev.placement = file.placement;
    tempCurrent.placement = tempPrevPlacement;
    tempPrev.updated = true;
    tempCurrent.updated = true;
    photos[pageOn - 1][49] = tempCurrent;
    photos[pageOn][index] = tempPrev;
    setPhotos(photos => [...photos]);
    return;
  }

  const photoDown = async (file) => {
    const { pageOn, index } = findPageAndIndexOf(file);
    if (pageOn < 0 || index < 0) return;
    if (pageOn === pageData.totalPages - 1 && index === 49) return;
    if (index < 49) {
      let tempNext = photos[pageOn][index + 1];
      let tempCurrent = photos[pageOn][index];
      let tempNextPlacement = tempNext.placement;
      tempNext.placement = file.placement;
      tempCurrent.placement = tempNextPlacement;
      tempNext.updated = true;
      tempCurrent.updated = true;
      photos[pageOn][index + 1] = tempCurrent;
      photos[pageOn][index] = tempNext;
      setPhotos(photos => [...photos]);
      return;
    }
    if(!photos[pageOn + 1]) {
      const { photos: files } = await fetchGalleryPhotos(pageOn + 1);
      photos[pageOn + 1] = files.sort((a, b) => a.placement - b.placement);
      setPhotos(photos => [...photos]);
    }
    let tempNext = photos[pageOn + 1][0];
    let tempCurrent = photos[pageOn][index];
    let tempNextPlacement = tempNext.placement;
    tempNext.placement = file.placement;
    tempCurrent.placement = tempNextPlacement;
    tempNext.updated = true;
    tempCurrent.updated = true;
    photos[pageOn + 1][0] = tempCurrent;
    photos[pageOn][index] = tempNext;
    setPhotos(photos => [...photos]);
    return;
  }

  return (
    <div>
      <PageSelector pages={pageData.totalPages} items={pageData.totalItems} currentPage={page} setPage={setPage} key='update-gallery' />
      {photos[page] ? photos[page].map(pic =>
        <div key={pic.filename} className='update-gallery-photo-container'>
          <img className="gallery-update-up-button" src="/images/up.png" alt="up" onClick={() => photoUp(pic)} />
          <img className="gallery-photo-update" src={pic.thumb ? pic.thumb : pic.filename} alt='gallery' key={pic.filename} />
          <img className="gallery-update-down-button" src="/images/down.png" alt="down" onClick={() => photoDown(pic)} />
        </div>
      ) : <></>}
      <button onClick={onSubmitHandler} >Zatwierdź</button>
    </div>
  )
}
