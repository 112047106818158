import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";

export const AddParentFolder = () => {
    const { id } = useParams();
    const { addParentFolder } = useContext(GlobalContext);
    const [isSubmitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const onFormSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        let response = addParentFolder(e.target.title.value.trim(), id)
        if(response) {
            navigate('/admin/parent');
        }
        setSubmitting(false);
    }

    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <label>
                    <span>Tytuł</span>
                    <input type='text' id='title' />
                </label>
                <button disabled={isSubmitting} >Dalej</button>
            </form>
        </div>
    )
}
